<template>
  <div class="selected-options-product selected-options-product__color">
    <div class="selected-options-product__color_badge" :style="{backgroundImage: `url(${option.color_image})`, backgroundColor: option.value}"></div>
    <span class="selected-options-product__color_label">{{option.value}}</span>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true
    }
  }
}
</script>
