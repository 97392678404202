<template>
  <div class="selected-options-product selected-options-product__size">
    <span class="selected-options-product__size_label" v-show="useLabel">{{ $t('size')}}:</span>
    <span class="selected-options-product__size_value">{{option.value}}</span>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true
    },
    useLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>
