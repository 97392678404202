<template>
  <div class="selected-options">
    <div v-for="(variant, index) in variantsSorted" :key="index" class="selected-option">
      <component
      v-if="availableComponent[variant.attribute_code] && variant.value"
      :is="availableComponent[variant.attribute_code]"
      :useLabel="useLabel"
      :option="variant" />
    </div>
  </div>
</template>

<script>

// load component custom
import ColorSelected from './SelectedOptions/ColorSelected'
import SizeSelected from './SelectedOptions/SizeSelected'
import GiftCardSelected from './SelectedOptions/GiftCardSelected'
import _ from 'lodash'

export default {
  props: {
    variants: {
      type: Array,
      required: true
    },
    useLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    availableComponent () {
      return {
        'color': ColorSelected,
        'size': SizeSelected,
        'giftCard': GiftCardSelected
      }
    },
    variantsSorted () {
      return _.sortBy(this.variants, ['option_id'])
    }
  },
  components: {
    ColorSelected,
    SizeSelected,
    GiftCardSelected
  }
}
</script>
